export const pt = {
  lang: "pt",
  messages: {
    "Create {{appName}} account": "Criar conta {{appName}}",
    "USER GUIDE": "GUIA UTILIZADOR",
    FAQs: "FAQs",
    "Text too small.": "Texto demasiado pequeno",
    "Invalid text.": "Texto inválido",
    "Invalid email address": "Email inválido",
    "Email address too small": "Email demasiado pequeno",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)": "A password deve conter 1 número 1 letra minúscula e 1 letra maiúscula (pode ter caracteres especiais, espaços não são permitidos)",
    "Password must be at least 8 characters long.": "A password deve ter no mínimo 8 caracteres.",
    "Upload file": "Upload ficheiro",
    "Uploading...": "Carregando...",
    "The username must only contain alphanumeric characters.": "Username só pode conter caracteres alfanuméricos ",
    "Username must be between 6 and 36 characters.": "Username tem de ter entre 6 e 36 caracteres.",
    Username: "Username",
    "New Password": "Nova password",
    "Reset password": "Redefinir password",
    "<strong>The password must be at least 8 characters long, have 1 number 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>": "<strong>A password deve ter no mínimo 8 caracteres, ter 1 número 1 letra minúscula e 1 letra maiúscula.</strong><p>Pode ter caracteres especiais.</p><p>Espaços não são permitidos.</p>",
    "Repeat password": "Repetir password",
    "Your account has been created successfully!": "A sua conta foi criada com sucesso",
    "Your account will be manually reviewed.": "A sua conta será revista manualmente.",
    "The username is already taken, please choose other.": "O nome de usuário já está em uso, escolha outro.",
    "You will receive an email as soon as the account becomes active.": "Irá receber um email assim que a sua conta ficar ativa.",
    SEND: "ENVIAR",
    "Finish creating {{appName}} account": "Finalizar criação de conta {{appName}}",
    "Thank you for confirming your email address.": "Obrigado por confirmar o seu endereço de email.",
    "Please define your userame and your identity verification.": "Por favor defina o seu username e a sua verificação de identidade.",
    "Please define your username.": "Por favor defina o seu username.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>": "<strong>Username sensível a maiúsculas.</strong><p>Se já existir um username igual ao escolhido iremos adicionar um número no final.</p><p>Exemplo: username1</p>",
    "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>": "<strong>A password deve conter: Números e Letras.</strong><p>Pode ter caracteres especiais.</p><p>Não são permitidos espaços.</p>",
    "<strong>Case-sensitive username.</strong>":"<strong>Username com distinção entre maiúsculas e minúsculas.</strong>",
    "{{appName}} account": "Conta {{appName}}",
    Identification: "Identificação",
    "Identification number": "Número de Identificação",
    "Identification document - front": "Documento de Identificação - frente",
    "Identification document - backside (if not already icluded on the file above)": "Documento de Identificação - verso (caso não esteja já incluído no documento acima)",
    Validate: "Validar",
    Address: "Endereço",
    "Post code": "Código postal",
    City: "Cidade",
    "Proof of address": "Comprovante de endereço",
    "<p>Dated less than 3 months.</p>": "<p>Com data inferior a 3 meses.</p>",
    "Registration successful!": "Registro efetuado com sucesso!",
    "Please check your email to confirm your identity.": "Por favor verifique o seu email para confirmar a sua identidade",
    '(Please check in the "Spam" box)': '(Veja também na caixa de "Spam", por favor)',
    "There are invalid fields in your request. Please check and re-submit:": "Existem campos inválidos na sua solicitação. Verifique e reenvie:",
    "There was an error trying to process your registration. Please try again, or contact us.": "Ocorreu um erro ao tentar processar seu cadastro. Por favor, tente novamente ou entre em contato conosco.",
    Name: "Nome",
    Surname: "Sobrenome",
    Country: "País",
    Currency: "Moeda",
    "Expected monthly volume": "Volume mensal previsto",
    "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform": "Página de interface para registro na plataforma de apostas <a href='{{url}}' target='_blank'>{{url}}</a>",
    "It only takes a minute": "Demora apenas um minuto",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.": "Detectámos que você já tem uma conta e reenviamos agora um email com os seus dados.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)': 'Por favor verifique o seu email (Veja também na pasta de "Spam", por favor).',
    "Register {{name}}": "Registro {{name}}",
    "Password updated successfully!": "Password atualizada com sucesso!",
    "Login at <a href='{{url}}'>{{url}}</a> and start betting!": "Aceda a <a href='{{url}}'>{{url}}</a> e comece a apostar!",
    "Login with the new password and start betting!": "Aceda com a nova password e comece a apostar!",
    "This username does not match the current password reset token.": "Este username não corresponde ao token atual de redefinição de password.",
    "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.": "Bem-vindo à plataforma de apostas Pro.{{name}}.com. As casas de apostas asiáticas mais respeitadas, onde os vencedores são bem-vindos.",
    "Register a new account on Pro.{{name}}": "Registre uma nova conta no Pro.{{name}}",
    "id-card-name-BR": "RG ou CNH",
    "id-card-name-EN": "Passaporte ou ID Local",
    "id-card-name": "CPF",
    "Recover Password": "Recuperar Password",
    "The email you submited is not associated with any account": "O email enviado não esta acossiado a nenhuma conta",
    "Please submit the email associated to your account": "Por favor envie o email associado com a sua conta",
    "Request sent successfully":"Pedido enviado com sucesso",
    "If we found an account with this email, an email was sent to you so you can proceed with resetting your password.": "Se encontrarmos uma conta com este e-mail, um e-mail será enviado a você para que você possa redefinir sua senha.",
    "Phone Number":"Número WhatsApp",
    "Number between 5 and 15 digits, can start with '+'":"Número entre 5 e 15 dígitos, pode começar com '+'",
    "Invalid Pattern":"Padrão inválido",
    "Invalid Cpf number": "Número de CPF inválido",
    "You must be 18 or older": "Você deve ter 18 anos ou mais",
    "Birthdate": "Data de nascimento",
    "Invalid date": "Data inválida",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.": "Nota: neste documento tem de ser visível o nome completo, foto, data de nascimento, e número CPF.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.": "Documento aceite: CPF ou CNH.<br>Nota: neste documento tem de ser visível o nome completo, foto, data de nascimento, e número CPF.",
    "Confirm Password":"Confirmar Password",
    "Confirm Email": "Confirmar Email",
    "(DD-MM-YYYY)": "(DD-MM-AAAA)",
    "Day": "Dia",
    "Month": "Mês",
    "Year": "Ano",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.": "Confirmo que li e concordo com os <a id='{{TermsAndConditions}}' href='#'>Termos e Condições</a> e a <a id='{{PrivacyPolicy}}' href='#'>Política de Privacida</a> e que tenho 18 ou mais anos.",
    "Terms And Conditions" : "Termos e Condições",
    "Value already in use": "Valor ja se encontra em uso",
    "Only_Crypto_deposit_available_in_{{ccy}}": "Apenas depósito em Crypto estará disponível em {{ccy}}",
    "It was not possible to use this validation code.":"Não foi possivel usar este código de validação."
  },
};
