export const es = {
  lang: "es",
  messages: {
    "Create {{appName}} account": "Crear {{appName}} cuenta",
    "USER GUIDE": "Guía del usuario",
    "FAQs": "Preguntas frecuentes",
    "Text too small.": "Texto demasiado pequeño.",
    "Invalid text.": "Texto no válido.",
    "Invalid email address": "Dirección de correo electrónico no válida",
    "Email address too small": "Dirección de correo electrónico demasiado pequeña",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)": "La contraseña debe contener 1 letra número 1 de minúsculas y 1 letra de casos superiores (puede tener caracteres especiales, espacios no permitidos)",
    "Password must be at least 8 characters long.": "La contraseña debe tener al menos 8 caracteres.",
    "Upload file": "Archivo de carga",
    "Uploading...": "Subiendo ...",
    "The username must only contain alphanumeric characters.": "El nombre de usuario solo debe contener caracteres alfanuméricos.",
    "Username must be between 6 and 36 characters.": "El nombre de usuario debe estar entre 6 y 36 caracteres.",
    "Username": "Nombre de usuario",
    "New Password": "Nueva contraseña",
    "Reset password": "Restablecer contraseña",
    "<strong>The password must be at least 8 characters long, have 1 number 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>": "<strong>La contraseña debe tener al menos 8 caracteres, tener 1 letra de minúscula número 1 y 1 letra mayúscula.</strong><p>Puede tener caracteres especiales.</p><p>No se permiten espacios.</p>",
    "Repeat password": "Repita la contraseña",
    "The username is already taken, please choose other.": "El nombre de usuario ya está tomado, por favor elija otro.",
    "Your account has been created successfully!": "¡Su cuenta ha sido creada con éxito!",
    "Your account will be manually reviewed.": "Su cuenta será revisada manualmente.",
    "You will receive an email as soon as the account becomes active.": "Recibirá un correo electrónico tan pronto como la cuenta se activa.",
    "SEND": "ENVIAR",
    "Finish creating {{appName}} account": "Terminar de crear {{appName}} cuenta",
    "Thank you for confirming your email address.": "Gracias por confirmar su dirección de correo electrónico.",
    "Please define your username and your identity verification.": "Defina su nombre de usuario y su verificación de identidad.",
    "Please define your username.": "Defina su nombre de usuario.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>": "<strong>Nombre de usuario sensible a la caja.</strong><p>Si ya hay un nombre de usuario igual al elegido, agregaremos un número al final.</p><p>Ejemplo: nombre de usuario1</p>",
    "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>": "<strong>La contraseña debe contener: números y letras.</strong><p>Puede tener caracteres especiales.</p><p>No se permiten espacios.</p>",
    "<strong>Case-sensitive username.</strong>": "<strong>Nombre de usuario sensible a la caja.</strong>",
    "{{appName}} account": "{{appName}} cuenta",
    "Identification": "Identificación",
    "Identification number": "Número de identificación",
    "Identification document - front": "Documento de identificación - Frente",
    "Identification document - backside (if not already icluded on the file above)": "Documento de identificación: trasero (si aún no es ICLUD en el archivo anterior)",
    "Validate": "Validar",
    "Address": "DIRECCIÓN",
    "Post code": "Código postal",
    "City": "Ciudad",
    "Proof of address": "Prueba de dirección",
    "<p>Dated less than 3 months.</p>": "<p>Fechado menos de 3 meses.</p>",
    "Registration successful!": "¡Inscripción exitosa!",
    "Please check your email to confirm your identity.": "Consulte su correo electrónico para confirmar su identidad.",
    '(Please check in the "Spam" box)': '(Marque en la casilla "spam")',
    "There are invalid fields in your request. Please check and re-submit:": "Hay campos no válidos en su solicitud. ",
    "There was an error trying to process your registration. Please try again, or contact us.": "Hubo un error tratando de procesar su registro. ",
    "Name": "Nombre",
    "Surname": "Apellido",
    "Country": "País",
    "Currency": "Divisa",
    "Expected monthly volume": "Volumen mensual esperado",
    "It only takes a minute": "Solo toma un minuto",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.": "Detectamos que ya tienes un {{appName}} cuenta y ahora reenviamos un correo electrónico con sus datos.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)': 'Marque su correo electrónico para confirmar su identidad (marque la casilla "spam")',
    "Register {{name}}": "Registro {{name}}",
    "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform": "Página de interfaz para registrarse en <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform",
    "Password updated successfully!": "Contraseña actualizada con éxito!",
    "Login at <a href='{{url}}'>{{url}}</a> and start betting!": "Iniciar sesión en <a href='{{url}}'>{{url}}</a> ¡Y comienza a apostar!",
    "Login with the new password and start betting!": "¡Inicie sesión con la nueva contraseña y comience a apuestas!",
    "This username does not match the current password reset token.": "Este nombre de usuario no coincide con el token de restablecimiento de contraseña actual.",
    "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.": "Bienvenido a Pro.{{name}}Plataforma de apuestas .com. ",
    "Register a new account on Pro.{{name}}": "Registre una nueva cuenta en Pro.{{name}}",
    "id-card-name-BR": "ID nacional o pasaporte",
    "id-card-name-EN": "Pasaporte o tarjeta de identificación local",
    "id-card-name": "ID nacional o pasaporte",
    "Recover Password": "Recuperar contraseña",
    "The email you submited is not associated with any account": "El correo electrónico que envió no está asociado con ninguna cuenta",
    "Please submit the email associated to your account": "Envíe el correo electrónico asociado a su cuenta",
    "Request sent successfully": "Solicitud enviada con éxito",
    "If we found an account with this email, an email was sent to you so you can proceed with resetting your password.": "Si encontramos una cuenta con este correo electrónico, se le envió un correo electrónico para que pueda continuar con su contraseña.",
    "Phone Number": "Número de teléfono",
    "Number between 5 and 15 digits, can start with '+'": "Número entre 5 y 15 dígitos, puede comenzar con '+'",
    "Invalid Pattern": "Patrón no válido",
    "Invalid Cpf number": "Número de CPF no válido",
    "You must be 18 or older": "Debes tener 18 años o más",
    "Birthdate": "Fecha de nacimiento",
    "Invalid date": "Fecha no válida",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.": "Nota: En este documento, el nombre completo, la foto, la fecha de nacimiento y la identificación nacional o el número de pasaporte deben ser visibles.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.": "Aceptado: ID nacional o licencia de conducir.<br>Nota: En este documento, el nombre completo, la foto, la fecha de nacimiento y la identificación nacional o el número de pasaporte deben ser visibles.",
    "Confirm Password": "confirmar Contraseña",
    "Confirm Email": "Confirmar correo electrónico",
    "(DD-MM-YYYY)": "(Dd-mm-yyyy)",
    "Day": "Día",
    "Month": "Mes",
    "Year": "Año",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.": "Confirmo que he leído y estoy de acuerdo con el <a id='{{TermsAndConditions}}' href='#'>Términos y condiciones</a> y <a id='{{PrivacyPolicy}}' href='#'>política de privacidad</a> y confirme que tengo 18 años de edad o más.",
    "Terms And Conditions": "Términos y condiciones",
    "Value already in use": "Valor ya en uso",
    "Only_Crypto_deposit_available_in_{{ccy}}": "Solo el depósito de cifrado está disponible en {{ccy}}",
    "It was not possible to use this validation code.": "No fue posible usar este código de validación."
  },
};
